<template>
  <div>
    <WBCheckin />
    <WBSession />
    <WBNotificationCenter />
    <div
      class="navbar sticky top-0 z-30 min-h-[42px] bg-base-200 px-2 backdrop-blur md:px-4 border-b flex items-center justify-between lg:justify-start"
    >
      <div class="flex-none">
        <label for="my-drawer" class="btn-sm btn-square btn lg:hidden">
          <Icon name="mdi:hamburger-menu" width="24" height="24" />
        </label>
      </div>

      <div
        class="font-semibold md:visible text-primary text-sm flex items-center gap-2"
      >
        <Icon name="fa-solid:store" width="16" height="16"></Icon>
        {{ session.tenant?.name }}
      </div>

      <div class="lg:ml-auto">
        <div class="flex-none">
          <div class="dropdown-end dropdown">
            <label
              tabindex="0"
              class="btn btn-xs btn-square hover:bg-transparent hover:border-transparent"
            >
              <Icon
                name="clarity:user-solid"
                width="18"
                height="18"
                class="text-primary"
              />
            </label>
            <ul
              tabindex="0"
              class="dropdown-content z-[100] menu p-2 shadow-md bg-base-100 rounded-box w-52 border"
            >
              <li class="px-4 py-2 text-primary font-semibold text-base">
                {{ session.user?.username }}
              </li>
              <hr class="my-2" />
              <li>
                <NuxtLink :to="getOIDCProviderUrl() + 'account'" external>
                  Profile
                </NuxtLink>
              </li>
              <li>
                <NuxtLink :to="{ path: tenantsUrl }">Switch tenant</NuxtLink>
              </li>
              <hr class="my-2" />
              <li>
                <NuxtLink :to="`/${tenantId}/d/logout`" no-prefetch external
                  >Logout</NuxtLink
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <main class="px-0 md:px-0 h-full"><slot /></main>
  </div>
</template>

<script setup lang="ts">
  import { useSessionStore } from "@/stores/session";
  import { useInterval } from "@vueuse/shared";

  const session = useSessionStore();
  const tenantId = useRoute().params.tenant;
  const tenantsUrl = ref<string>("/");

  useHead({
    link: [
      {
        rel: "stylesheet",
        href: useTenantAssetThemeUrl(useRoute().params.tenant as string)
      }
    ]
  });

  useInterval(2 * 60 * 1000, {
    immediate: true,
    callback: () => {
      useTenantCheckin();
    }
  });
</script>
